@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto"),
    url("./fonts/robot-slab.ttf") format("truetype");
}

@font-face {
  font-family: "Foregen";
  src: local("Foregen"),
    url("./fonts/foregen.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Sans";
  src: local("Sans"),
    url("./fonts/sans.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: local("RobotoSlab"),
    url("./fonts/robot-slab.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoSlabBold";
  src: local("RobotoSlabBold"),
    url("./fonts/roboto-slab-bold.ttf") format("truetype");
  font-weight: normal;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFF7E9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButton-contained {
  background-color: #a4243d !important;
  text-transform: none !important;
  color: white !important;
  border-radius: 7px !important;
  font-family: 'Roboto' !important;
  font-weight: bold !important;
}

.MuiButton-outlined {
  border-color: #a4243d !important;
  text-transform: none !important;
  border-radius: 7px !important;
  color: #a4243d !important;
  font-family: 'Roboto' !important;
}

.MuiAccordionSummary-content  {
  margin:0px !important;
}

.MuiAccordionSummary-root {
  min-height: 0px !important;
  background: #FFF5E8 !important;
}

.MuiAccordion-root {
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  background: #FFF5E8 !important;
}

.MuiAccordion-root::before {
  background: #FFF5E8 !important;
}

.MuiAccordion-root .MuiButtonBase-root {
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.Mui-expanded {
  margin:0px !important;
}

.MuiAccordionDetails-root {
  padding: 5px !important;
  background: #FFF5E8 !important;
}

.MuiRadio-root .MuiSvgIcon-root {
  height: 15px !important;
  /* color: #a4243d; */
}

.MuiRadio-root {
  align-self: flex-start !important;
}

/* .MuiButtonBase-root:hover {
  background-color: white !important;
  color: black !important;
  border:1px solid #a4243d !important
} */

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.lan-iframe {
  align-self: end;
}

.lan-iframe iframe {
  width: 40vw;
  height: 280px;
}

.recipie-iframe  iframe {
  display: block;   
  background: black;   
  border: none;         
  width: 40vw;
  height: 20vw;
}

.itemdet-recipie-iframe iframe {
  display: block;   
  background: white;
  width: 100%;
}

.gateway-cont-desk {
  width: 50vw !important;
  height: 100% !important;
  margin:20px;
}

.gateway-cont-mob {
  width: 100vw !important;
  height: 100% !important;
}

.btn-curved {
  border-radius: 50%;
}

.mySwiper {
  border-radius: 5px;
}


.prodImgDeskCat {
  max-width:300px !important
}

.prodImgDeskProd {
  max-width:500px !important
}

.prodImgDeskHome {
  max-width:300px !important
}

.nav-ic {
  margin-bottom: 5px;
}

/* iframe
{
    display: none;
} */